let prevScrollpos = $(window).scrollTop();
$(window).on('scroll', function () {
  let scrollPos = $(document).scrollTop();
  let currentScrollPos = $(window).scrollTop();
  if (scrollPos > 185.19) {
    $("#back-top").css("display", "flex");
    if (prevScrollpos > currentScrollPos) {
      $(".header").css("top", "-93px");
    } else {
      $(".header").css("top", "-185.19px");
    }
    prevScrollpos = currentScrollPos;
  } else {
    $("#back-top").css("display", "none");
    if (prevScrollpos > currentScrollPos) {
      $(".header").css("top", "0");
    } else {
      $(".header").css("top", "-185.19px");
    }
    prevScrollpos = currentScrollPos;
  }
});

$('.hospitals__region--title').on('click', function() {
  $(this).next('.hospitals__list').toggleClass('show');
  $(this).toggleClass('isActive');
});

$(window).resize(function(){
  let windowWidth = $(window).width();
  if (windowWidth > 1024) {
    $('.hospitals__region--title').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
  });
  }
});